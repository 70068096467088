<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div class="col-md-11">
              <h1>Exit Benefits</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
            </div>
          </div>
          <div class="col-md-12 ml-auto mr-auto">
            <v-text-field v-model="searchPersonnel"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details></v-text-field>
          </div>
          <v-data-table loading-text="Loading data. Please wait...."
                        :loading="loading"
                        :headers='headers'
                         :search="searchPersonnel"
                        :items="exitBenefits"
                        :single-select="false"
                        v-model="selected"
                        item-key="requestId">
            <template v-slot:item.benefitsLiableToTax="{ item }">
              <div class="d-flex">
                {{item.benefitsLiableToTax | formatMoney}}
                <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
              </div>
            </template>
            <template v-slot:item.relief="{ item }">
              <div class="d-flex">
                {{item.relief | formatMoney}}
                <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
              </div>
            </template>
            <template v-slot:item.taxableIncome="{ item }">
              <div class="d-flex">
                {{item.taxableIncome | formatMoney}}
                <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
              </div>
            </template>
            <template v-slot:item.paye="{ item }">
              <div class="d-flex">
                {{item.paye | formatMoney}}
                <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
              </div>
            </template>
            <template v-slot:item.terminalBenefitItems="{ item }">
              <div class="d-flex">
                <v-btn  color="blue"  @click.prevent="viewDateModal(item)">
                  View
                </v-btn>
                <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
              </div>
            </template>
          </v-data-table>

          <!-- view dates modal   -->
          <v-dialog v-model="dateDialog" persistent max-width="600">
            <v-card>
              <v-card-title class="headline text-center"><h3>Terminal BenefitItems</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-data-table
                  :headers='terminalBenefitItemHeader'
                  :items="terminalBenefitItems"
                >
                  <template v-slot:item.amount="{ item }">
                    <div class="d-flex">
                      {{item.amount | formatMoney}}
                      <!-- <v-icon medium @click="viewDateModal(item)">mdi-eye-outline</v-icon> -->
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dateDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Delete modal  -->
          <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Do you want to delete ?</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Yes'"
                        :btnType="'Submit'"
                        @onClick="deleteOk"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="dialog = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>

import Button from '@/components/ui/form/Button.vue'
import { benefitService } from '@/services'

export default {
  components: {
    Button
  },
  data () {
    return {
      loading: false,
      alertMessage: '',
      alert: false,
      searchPersonnel: '',
      valid: false,
      dialog: false,
      data: '',
      dateDialog: false,
      alertType: '',
      rejectDialog: false,
      action: '',
      loading: false,
      item: '',
      bulk: false,
      terminalBenefitItems: [],
      selected: [],
      formData: {
        comments: '',
        id: ''
      },
      leaveDays: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      exitBenefits: [],
      headers: [
        {
          text: 'First Name',
          value: 'employee.firstName'
        },
        {
          text: 'Last Name',
          value: 'employee.lastName'
        },
        {
          text: 'Exit Reason',
          value: 'exitReason.name'
        },
        {
          text: 'Benefits Liable To Tax',
          value: 'benefitsLiableToTax'
        },
        {
          text: 'Paye',
          value: 'paye'
        },
        // {
        //   text: 'Comment',
        //   value: 'comment'
        // },
        {
          text: 'Terminal Benefit Items',
          value: 'terminalBenefitItems'
        }
      ],
      terminalBenefitItemHeader: [
        {
          text: 'Multiplier',
          value: 'multiplier'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Amount',
          value: 'amount'
        }
      ]

    }
  },
  computed: {
    user () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },

    viewDateModal (item) {
      this.dateDialog = true

      this.terminalBenefitItems = item.terminalBenefitItems
    },
    Add () {
      this.$router.push('/exit/add')
    },
    openDeleteModal (item) {
      this.data = item
      this.dialog = true
    },
    deleteOk () {
      return ''
    }
  },
  mounted () {
    this.loading = true
    benefitService.getTerminalBenfit().then((res) => {
      this.exitBenefits = res.data
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.action-select {
 padding-top: 24px;
}
</style>
